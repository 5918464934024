.contain-select {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 2px solid var(--light-purple);
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
}

.placeholder-custom {
  color: var(--light-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 16px;
}

.arrow-select {
  width: 22px;
  height: 22px;
  margin-right: 20px;
}

.value-select {
  color: var(--dark-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 16px;
}
.label-input {
  color: var(--stroke-dark-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 630px) {
  .arrow-select {
    margin-right: 6px;
  }
  .placeholder-custom {
    font-size: 12px;
    margin-left: 5px;
  }
  .value-select {
    font-size: 11px;
    margin-left: 6px;
  }
}
