.input-plate {
  height: 30px;
  outline: none;
  border-radius: 8px;
  padding-left: 16px;
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white, #fff);
  font-size: 14px;
}

.input-plate::placeholder {
  color: var(--light-purple);
  font-family: Mark Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 630px) {
  .input-plate {
    padding-left: 6px;
  }
  .input-plate::placeholder {
    font-size: 12px;
  }
}
