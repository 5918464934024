@font-face {
  font-family: "Mark Pro";
  src: url("styles/fonts/Mark-Pro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mark Pro";
  src: url("styles/fonts/Mark-Pro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mark Pro";
  src: url("styles/fonts/Mark-Pro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mark Pro";
  src: url("styles/fonts/Mark-Pro-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: "Mark Pro", Arial, Verdana, sans-serif;
  margin: 0;
}

p {
  font-family: "Mark Pro";
  margin-bottom: 0px !important;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
