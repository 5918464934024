.DayPicker {
  display: inline-block;
  font-size: 14px;
  margin-top: 15px;
}

.DayPicker-Day {
  color: var(--dark-Purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.DayPicker-wrapper {
  padding-bottom: 0px !important;
}
.DayPicker-Month {
  margin: 0px !important;
}

.swal2-popup {
  font-size: 1.4rem !important;
}

.label-paracaidas {
  color: var(--stroke-dark-purple);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input#paracaidas {
  width: 20px;
  height: 20px;
}
