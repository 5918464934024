.style-modal {
  border: 4px solid var(--light-purple);
  border-radius: 16px;
}

.contain-btn-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 630px) {
  .contain-btn-modal {
    flex-direction: column;
  }
}
