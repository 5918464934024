.custom-btn {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.btn-send-appointment {
  outline: none;
  border: none;
  height: 50px;
  /* background: #e2e2e2; */
  border-radius: 8px;
  font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.btn-contained {
  width: 170px;
  height: 36px;
  border-radius: 200px;
  border: 3px solid var(--stroke-purple);
  background: var(--purple);
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn-contained:hover {
  background: var(--stroke-purple);
}

.btn-outlined {
  width: 170px;
  height: 36px;
  border-radius: 200px;
  border: 3px solid var(--purple);
  box-shadow: 0px 2px 20px 0px rgba(24, 14, 49, 0.06);
  color: var(--purple);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: var(--white);
}
.btn-outlined:hover {
  border: 3px solid var(--stroke-purple);
  color: var(--stroke-purple);
}

.btn-disabled {
  width: 170px;
  height: 36px;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border-radius: 200px;
  border: 3px solid var(--light-purple);
  background: var(--ultra-light-purple);
}

@media screen and (max-width: 630px) {
  .custom-btn {
    width: 270px;
    margin: 0px !important;
    margin-bottom: 5px !important;
  }
}
