.container-hours {
  padding-top: 10px;
  border: 2px solid var(--light-purple);
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation-duration: 0.5s;
  border-top: none;
  margin-top: -10px;
}

.subcontainer-hours {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: scroll;
  height: 230px;
  overflow-x: hidden;
  padding-left: 20px;
  padding-bottom: 20px;
}

.subcontainer-hours::-webkit-scrollbar {
  -webkit-appearance: none;
}

.subcontainer-hours::-webkit-scrollbar:vertical {
  width: 5px;
}

.subcontainer-hours::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.subcontainer-hours::-webkit-scrollbar:horizontal {
  height: 10px;
}

.subcontainer-hours::-webkit-scrollbar-thumb {
  background-color: var(--light-purple);
  border-radius: 10px;
}

.subcontainer-hours::-webkit-scrollbar-track {
  border-radius: 0px 20px 0px 0px;
  background-color: var(--border-cards);
}

.container-buttons-option-hours {
  width: 80px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  margin-top: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 630px) {
  .container-hours {
    padding: 0px;
    height: 225px;
    padding-top: 10px;
  }
  .subcontainer-hours {
    height: 100%;
  }

  .container-buttons-option-hours {
    margin-top: 10px;
  }
}
