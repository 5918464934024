/* ------------------------------ CONTAINER ----------------------------- */
.container-app {
  background-color: var(--purple);
  height: 100vh;
  background-image: url("../images/background-white.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* ------------------------------------ LOGO ------------------------------------ */

.logo-auta {
  width: 185px;
  height: 90px;
}

/* ---------------------------------------- CARD --------------------------------------- */

.card-container {
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white);
}

.subcontainer-card-turnos {
  width: 510px;
}

.container-loading {
  background-color: white;
  border-radius: 16px;
  border: 2px solid var(--light-purple);
}

.title-card {
  width: 510px;
  height: 45px;
  border-radius: 8px;
  background: var(--purple);
}

.text-title-card {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.circle-step {
  border-radius: 50px;
  width: 14px;
  height: 14px;
  background-color: var(--purple);
  z-index: 1;
  position: absolute;
  margin-left: -5px;
}

.input-form {
  width: 241px;
  height: 30px;
  outline: none;
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white, #fff);
  padding: 8px 16px 8px 16px;
}

.input-form::placeholder {
  color: var(--light-purple);
  font-family: Mark Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.displayNone {
  display: none;
}

.text-type-appointment {
  color: var(--dark-purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-data-car {
  color: var(--purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-type-car {
  color: var(--dark-purple);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-date-resumen {
  color: var(--purple);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

.text-title-modal {
  color: var(--purple);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-description-modal {
  color: var(--purple);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-success {
  padding: 20px;
  border-radius: 16px;
  width: 682px;
  height: 283px;
  overflow: auto;
  position: relative;
  background-color: var(--purple);
  background-image: url("../images/background-success-modal.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.card-component {
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white, #fff);
}

.text-date {
  color: var(--purple);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-content {
  border-radius: 16px !important;
}

.divider-option {
  height: 2px;
  width: 100%;
  background: var(--light-purple);
}

@media screen and (max-width: 630px) {
  .container-app {
    background-color: var(--purple);
    height: 100vh;
    background-image: url("../images/background-app-mobile.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .title-card {
    width: 100%;
  }

  .subcontainer-card-turnos {
    width: 320px;
  }

  .logo-auta {
    width: 125px;
    height: 60px;
  }
  .card-component {
    width: 345px;
  }
  .text-title-modal {
    font-size: 18px;
  }
  .btn-mobile {
    width: 148px;
  }
  .text-title-card {
    font-size: 16px;
  }
  .modal-success {
    width: 343px;
    height: 226px;
    background-color: var(--purple);
    background-image: url("../images/background-modal-mobile.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .text-title-modal {
    font-size: 20px !important;
  }
  .text-description-modal {
    font-size: 14px;
    margin-bottom: 30px !important;
  }
}
