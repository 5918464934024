.container-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-purple);
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation-duration: 0.5s;
  border-top: none;
  margin-top: -10px;
}
