@import "~bootstrap/scss/bootstrap";

:root {
  //----------------------------- PURPLE ----------------------------
  --black-purple: #180e31;
  --stroke-dark-purple: #321670;
  --dark-purple: #482481;
  --dark-purple-two: #5f35bc;
  --stroke-purple: #7340d8;
  --purple: #864bfa;
  --light-purple: #d7c9ee;
  --ultra-light-purple: #e8e1ef;

  //----------------------------- GRAY ----------------------------

  --light-grey: #eeeeee;

  //----------------------------- WHITE ----------------------------

  --white: #ffffff;

  //----------------------------GOOGLE--------------------------

  --google-red: #db4437;
}
