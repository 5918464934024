.container-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-login {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 20px rgb(57 56 57 / 10%);
}

.btn-login-google {
  width: 280px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--google-red);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}

.google-icon-login {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.title-login {
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  color: var(--purple);
}
